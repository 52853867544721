import React, { useEffect, useState } from "react";

//Style
import "./SalesforceBgScreen.css";

const SalesforceBgScreen = () => {
  const [currentImg, setCurrentImg] = useState(
    "https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/salesforce-bg/regular-days-img.webp"
  );

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;

    if (currentMonth === 5) {
      // Si es mayo (el mes de las madres)
      setCurrentImg(
        "https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/salesforce-bg/mom-day-salesforce-bg.webp"
      );
    }
  }, []);

  return (
    <div className="salesforce-bg-container">
      <img className="sb-img" src={currentImg} alt="" />
    </div>
  );
};

export default SalesforceBgScreen;
