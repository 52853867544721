import React from "react";

//Components
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import BannerSection from "../../components/BannerSection/BannerSection";
import CounterSection from "../../components/CounterSection/CounterSection";
import PaymentSection from "../../components/PaymentSection/PaymentSection";
import JobOfferSection from "../../components/JobOfferSection/JobOfferSection";
import DigitalSignSection from "../../components/DigitalSignSection/DigitalSignSection";
import FinancialProcessSection from "../../components/FinancialProcessSection/FinancialProcessSection";
import SalesforceSection from "../../components/SalesforceSection/SalesforceSection";
import Footer from "../../components/Footer/Footer";

//Data
import dataCounterSection from "../../json/CounterSection/infoCounter.json"; // Data Contador
import dataBtnsPaymentSection from "../../json/PaymentSection/buttons.json"; // Data Buttons Payment methods
import dataBtnsDigitalSign from "../../json/DigitalSignSection/DSButtons.json"; // Data Buttons Digital Sign
import dataSteps from "../../json/FinancialProcessSection/FPSteps.json"; //Data Steps
import dataBtnsSteps from "../../json/FinancialProcessSection/FPbutton.json"; //Data Buttons Financial Process Section

const Home = ({navbarData, footerInfo, footerSocial, footerPayment}) => {
  return (
    <>
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
      <BannerSection />
      <CounterSection infoCounter={dataCounterSection.data} />
      <PaymentSection buttons={dataBtnsPaymentSection.data} />
      <JobOfferSection />
      <DigitalSignSection buttons={dataBtnsDigitalSign.data} />
      <FinancialProcessSection
        steps={dataSteps.data}
        buttons={dataBtnsSteps.data}
      />
      <SalesforceSection />
      <Footer
        info={footerInfo.data}
        social={footerSocial.data}
        payment={footerPayment.data}
      />
    </>
  );
};

export default Home;
