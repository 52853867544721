import React from "react";

//style
import "./SalesforceSection.css";

const SalesforceSection = () => {
  return (
    <section className="salesforce-section">
      <div className="salesforce-subcontainer">
        <h1 className="salesforce-title">
          <strong>NUESTRO CORE BANCARIO</strong> ESTA DESARROLLADO SOBRE
          SALESFORCE
        </h1>
        <div className="sf-info-container">
          <div className="sf-video-container">
            <img className="deco-img" src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/deco-img.webp" alt="" loading="lazy" />
            <video className="sf-video" controls>
              <source src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/videos/salesforce-video.mp4" type="video/mp4" />
            </video>
          </div>
          <a
            href="https://www.salesforce.com/mx/?ir=1"
            title="Salesforce"
            target="_blank"
            className="sf-anchor"
            rel="noreferrer"
          >
            <img className="salesforce-logo" src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/salesforce-logo.webp" alt="" loading="lazy" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default SalesforceSection;
