import React from "react";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Carousel from "react-grid-carousel";

//Style
import "./FinancialProcessSection.css";

//Component
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const FinancialProcessSection = ({ steps, buttons }) => {
  const elements = [];

  steps.map((step, i) => {
    return elements.push(
      <div key={i} className="step-container">
        <div className="step-info-container">
          <img src={step.img} alt={step.alt} className="step-img" />
          <div className="step-number">
            Paso <strong>{step.stepValue}</strong>
          </div>
          <div className="step-text-container">
            <h3 className="step-subtitle">{step.subtitle}</h3>
            <p className="step-content-text">{step.content}</p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <section className="financial-process-section" id="process">
      <div className="financial-process-subcontainer">
        <div className="fp-titles">
          <h1 className="fp-title">
            PROCESO DE <strong>FINANCIACIÓN</strong>
          </h1>
          <h2 className="fp-subtitle">
            Adquirir un crédito nunca había sido tan fácil, rápido y confiable
          </h2>
        </div>
        <div className="fp-steps-container-tablet">
          <Carousel
            cols={2}
            rows={1}
            loop
            autoplay={3000}
            showDots={true}
            mobileBreakpoint={479}
            hideArrow={true}
          >
            {steps.map((step, i) => {
              return (
                <Carousel.Item key={i}>
                  <div key={i} className="step-container">
                    <div className="step-info-container">
                      <img src={step.img} alt={step.alt} className="step-img" />
                      <div className="step-number">
                        Paso <strong>{step.stepValue}</strong>
                      </div>
                      <div className="step-text-container">
                        <h3 className="step-subtitle">{step.subtitle}</h3>
                        <p className="step-content-text">{step.content}</p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        <div className="fp-steps-container-mobile">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={"bottom"}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
        <div className="fp-steps-container">
          {steps.map((step, i) => {
            return (
              <div key={i} className="step-container">
                <div className="step-info-container">
                  <img src={step.img} alt={step.alt} className="step-img" />
                  <div className="step-number">
                    Paso <strong>{step.stepValue}</strong>
                  </div>
                  <div className="step-text-container">
                    <h3 className="step-subtitle">{step.subtitle}</h3>
                    <p className="step-content-text">{step.content}</p>
                  </div>
                </div>
                <img
                  style={
                    step.hasNextStep
                      ? { display: "unset" }
                      : { display: "none" }
                  }
                  src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/process-section/mint-arrow.svg"
                  alt=""
                  className="next-icon"
                  loading="lazy"
                />
              </div>
            );
          })}
        </div>
        <div className="fp-btn-container">
          <ButtonComponent btn={buttons[0]} />
        </div>
      </div>
      <div className="bg-skew bottom"></div>
    </section>
  );
};

export default FinancialProcessSection;
