import React, { useEffect } from "react";

//Style
import './Counter.css';

const Counter = ({ info }) => {
  useEffect(() => {
    const elements = document.querySelectorAll(".total-num");
    const speed = 2000;
    elements.forEach((element) => {
      const animate = () => {
        const value = +element.title;
        const data = +element.innerText;

        const time = value / speed;
        if (data < value) {
          element.innerText = Math.ceil(data + time);
          setTimeout(animate, 1);
        } else {
          element.innerText = value;
          setTimeout(() => {
            element.textContent = value.toLocaleString("en");
          }, 1);
        }
      };
      animate();
    });
  }, []);

  return (
    <div className="counter-container">
      <img
        src={info.img}
        style={info.hasImage ? { display: "unset" } : { display: "none" }}
        alt=""
        className="img-counter"
      />
      <div className="nums-container">
        <h2
        className="counter-txt"
          style={
            info.signPos === "left" ? { display: "unset" } : { display: "none" }
          }
        >
          {info.sign}
        </h2>
        <h2 className="counter-txt total-num" title={info.number}>
          0
        </h2>
        <h2
          style={info.hasComplement ? { display: "unset" } : { display: "none" }}
          className="counter-txt"
        >
          {info.complement}
        </h2>
        <h2
        className="counter-txt"
          style={
            info.signPos === "right"
              ? { display: "unset" }
              : { display: "none" }
          }
        >
          {info.sign}
        </h2>
      </div>
      <div className="counter-subtitle">
        {info.subtitle}
      </div>
    </div>
  );
};

export default Counter;
