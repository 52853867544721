import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

//Style
import "./Navbar.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const Navbar = () => {
  const { nbContactInView } = useContext(NavbarContext);

  const [viewMenu, setViewMenu] = useState(false);

  const showMenu = () => {
    setViewMenu(!viewMenu);
  };

  return (
    <nav className={`navbar-container ${!nbContactInView ? "fixed-nb" : ""}`}>
      <div className="navbar-subcontainer">
        <Link className="logo-container" to="/">
          <img
            src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/logo.webp"
            srcset="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/alt-imgs/logo.webp?id=1 160w, https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/logo.webp"
            sizes="(max-width: 1023px) 160px, (min-width: 1024px)"
            alt="Logo de CrediSeguro"
            className="logo-img"
            loading="lazy"
          />
        </Link>
        <div className="options-container">
          <a href="/#" className="option">
            Inicio
          </a>
          <a href="/#pay-forms" className="option">
            Formas de Pago
          </a>
          <a href="/#why-choose-us" className="option">
            ¿Por qué elegirnos?
          </a>
          <a href="/#finance" className="option">
            Financia con Nosotros
          </a>
          <a href="/#process" className="option">
            Nuestro Proceso
          </a>
          <a href="/ofertas-de-empleo" className="option">
            Ofertas de Empleo
          </a>
          <a
            href="https://pagos.crediseguro.co/#/"
            target="_blank"
            className="option pay-btn"
            title="Pago en Línea"
            rel="noreferrer"
          >
            Pago en Línea
          </a>
        </div>
        <div className="nb-pay-btn-container">
          <a
            href="https://pagos.crediseguro.co/#/"
            target="_blank"
            className="option pay-btn nb-mobile"
            title="Pago en Línea"
            rel="noreferrer"
          >
            Pago en Línea
          </a>
        </div>
        <div onClick={() => showMenu()} className="ham-menu-container">
          <img
            src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/ham-menu-icon.webp"
            alt=""
            className="ham-menu-icon"
            loading="lazy"
          />
        </div>
        <div
          className={
            viewMenu
              ? "menu-options-container open-menu"
              : "menu-options-container close-menu"
          }
        >
          <div className="anchor-container underline navbar-menu">
            <a
              onClick={() => setViewMenu(false)}
              className="anchor"
              href="/#"
              id="Ventajas_del_Proyecto"
            >
              Inicio
            </a>
          </div>
          <div className="anchor-container underline navbar-menu">
            <a
              onClick={() => setViewMenu(false)}
              className="anchor"
              href="/#pay-forms"
            >
              Formas de Pago
            </a>
          </div>
          <div className="anchor-container underline navbar-menu">
            <a
              onClick={() => setViewMenu(false)}
              className="anchor"
              href="/#why-choose-us"
            >
              ¿Por qué elegirnos?
            </a>
          </div>
          <div className="anchor-container underline navbar-menu">
            <a
              onClick={() => setViewMenu(false)}
              className="anchor"
              href="/#finance"
            >
              Financia con Nosotros
            </a>
          </div>
          <div className="anchor-container underline navbar-menu">
            <a
              onClick={() => setViewMenu(false)}
              className="anchor"
              href="/#process"
            >
              Nuestro Proceso
            </a>
          </div>
          <div className="anchor-container underline navbar-menu">
            <a
              onClick={() => setViewMenu(false)}
              className="anchor"
              href="https://crediseguro.my.salesforce.com/"
            >
              Acceso Funcionarios
            </a>
          </div>
          <div className="anchor-container underline navbar-menu">
            <a
              onClick={() => setViewMenu(false)}
              className="anchor"
              href="https://portal.crediseguro.co/login"
            >
              Portal Intermediarios
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
