import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Global Data
import dataSMCN from "./json/ContactNavbar/socialMedia.json"; //Data Social media Navbar contact
import dataInfoFooter from "./json/Footer/footerInfoContent.json"; //Data Info Footer
import dataSocialMedia from "./json/Footer/socialMedia.json"; //Data Footer Social Media
import dataPMFooterLogos from "./json/Footer/paymentMethods.json"; //Data Payment methods logos

//Style
import "./App.css";

//Context
import { NavbarContext } from "./context/NavbarContext";
import { LoadingContext } from "./context/LoadingContext";

//Pages
import Home from "./pages/home/Home";
import WorkFormScreen from "./pages/WorkFormScreen/WorkFormScreen";
import NotfoundScreen from "./pages/NotFoundScreen/NotfoundScreen";
import CreateCreditFirstScreen from "./pages/CreateCreditFirstScreen/CreateCreditFirstScreen";
import CreditTypeScreen from "./pages/CreditTypeScreen/CreditTypeScreen";
import NewCreditDocScreen from "./pages/NewCreditDocScreen/NewCreditDocScreen";
import RenovationDocScreen from "./pages/RenovationDocScreen/RenovationDocScreen";
import NewCreditFormScreen from "./pages/NewCreditFormScreen/NewCreditFormScreen";
import RenovationFormScreen from "./pages/RenovationFormScreen/RenovationFormScreen";
import ThanksScreen from "./pages/ThanksScreen/ThanksScreen";
import SalesforceBgScreen from "./pages/SalesforceBgScreen/SalesforceBgScreen";
import JobOfferPageScreen from "./pages/JobOfferPageScreen/JobOfferPageScreen";
import JobOfferScreen from "./pages/JobOfferScreen/JobOfferScreen";

function App() {
  const { ref: nbContact, inView: nbContactInView } = useInView();

  const [loading, setLoading] = useState(false);

  return (
    <NavbarContext.Provider value={{ nbContact, nbContactInView }}>
      <LoadingContext.Provider value={{ loading, setLoading }}>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Home
                  navbarData={dataSMCN}
                  footerInfo={dataInfoFooter}
                  footerSocial={dataSocialMedia}
                  footerPayment={dataPMFooterLogos}
                />
              }
            />
            <Route
              exact
              path="/trabaja-con-nosotros"
              element={
                <WorkFormScreen
                  navbarData={dataSMCN}
                  footerInfo={dataInfoFooter}
                  footerSocial={dataSocialMedia}
                  footerPayment={dataPMFooterLogos}
                />
              }
            />
            <Route
              exact
              path="/inicio-crear-credito"
              element={
                <CreateCreditFirstScreen
                  navbarData={dataSMCN}
                  footerInfo={dataInfoFooter}
                  footerSocial={dataSocialMedia}
                  footerPayment={dataPMFooterLogos}
                />
              }
            />
            <Route
              exact
              path="/tipo-credito"
              element={<CreditTypeScreen navbarData={dataSMCN} />}
            />
            <Route
              exact
              path="/credito-nuevo-adjuntos"
              element={<NewCreditDocScreen navbarData={dataSMCN} />}
            />
            <Route
              exact
              path="/renovacion-adjuntos"
              element={<RenovationDocScreen navbarData={dataSMCN} />}
            />
            <Route
              exact
              path="/formulario-crear-credito"
              element={<NewCreditFormScreen navbarData={dataSMCN} />}
            />
            <Route
              exact
              path="/formulario-renovacion"
              element={<RenovationFormScreen navbarData={dataSMCN} />}
            />
            <Route
              exact
              path="/agradecimientos"
              element={<ThanksScreen navbarData={dataSMCN} />}
            />
            <Route
              exact
              path="/salesforce-background"
              element={<SalesforceBgScreen navbarData={dataSMCN} />}
            />
            <Route
              exact
              path="/ofertas-de-empleo"
              element={<JobOfferPageScreen navbarData={dataSMCN} />}
            />
            <Route
              exact
              path="/ofertas-de-empleo/:name"
              element={<JobOfferScreen navbarData={dataSMCN} />}
            />
            <Route
              path="*"
              element={<NotfoundScreen navbarData={dataSMCN} />}
            />
          </Routes>
        </Router>
      </LoadingContext.Provider>
    </NavbarContext.Provider>
  );
}

export default App;
