import React, { useState,useContext } from "react";

//Style
import "./FormWWu.css";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const FormWWU = () => {
  const [checkedValue, setCheckedValue] = useState(false);

  const { setLoading } = useContext(LoadingContext);

  const [disabledBtn, setDisabledBtn] = useState(true);

  const [brokerInfo, setBrokerInfo] = useState({
    agency_name: "",
    location_agency: "",
    name: "",
    email: "",
    phone: "",
    type: "",
    message: "",
    checked: false
  });

  const handleForm = (e) => {
    e.target.name === "checked" && setCheckedValue(!checkedValue);
    const valueCheck = e.target.name === "checked" && e.target.checked;

    if (
      e.target.name === "agency_name" ||
      e.target.name === "location_agency" ||
      e.target.name === "name" ||
      e.target.name === "message"
    ) {
      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.name === "type") {
      if (e.target.value === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.name === "email") {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (emailPattern.test(e.target.value)) {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    if (e.target.name === "phone") {
      e.target.value = e.target.value.replace(/\D/g, "");
      if (/^\s*3/.test(e.target.value.trim())) {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    if (e.target.name === "checked") {
      if (valueCheck === true) {
        e.target.parentElement.classList.remove("error");
      } else {
        e.target.parentElement.classList.add("error");
      }
    }

    setBrokerInfo({
      ...brokerInfo,
      [e.target.name]: e.target.value,
      checked: valueCheck,
    });

    if (
      brokerInfo.agency_name &&
      brokerInfo.agency_name !== "" &&
      brokerInfo.location_agency &&
      brokerInfo.location_agency !== "" &&
      brokerInfo.name &&
      brokerInfo.name !== "" &&
      brokerInfo.email &&
      brokerInfo.email !== "" &&
      brokerInfo.phone &&
      brokerInfo.phone !== "" &&
      brokerInfo.type &&
      brokerInfo.type !== "" &&
      brokerInfo.message &&
      brokerInfo.message !== "" &&
      valueCheck !== false
    ) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const apiUrl = "https://crediseguro-back.click/send";
    const data = {
      destinatario: [
        "aliados@crediseguro.co",
        "dirmarketing@cavca.com.co",
      ],
      asunto: "Solicitud Información Intermediario",
      mensaje: `Hola mi nombres es ${brokerInfo.name}, el nombre de mi agencia es ${brokerInfo.agency_name} ubicada en la ciudad de ${brokerInfo.location_agency}, con enfasis en los seguros de tipo ${brokerInfo.type}, mi información de contacto es: Tel: ${brokerInfo.phone} y mi correo eléctronico es ${brokerInfo.email}. ${brokerInfo.message}.`,
    };

    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch(apiUrl, options)
      .then(() => {
        setCheckedValue(false);
        setBrokerInfo({
          agency_name: "",
          location_agency: "",
          name: "",
          email: "",
          phone: "",
          type: "",
          message: "",
          checked: false,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      }).finally(() => {
        setLoading(false);
      })
  };

  return (
    <div className="form-container">
      <div className="header-title-container">
        <h1>
          Ingresa tus Datos y Nos Pondremos en Contacto Contigo tan Pronto sea
          Posible
        </h1>
      </div>
      <form onSubmit={handleSubmit} className="form-element">
        <div className="grid-inputs-container">
          <label>
            <input
              onChange={(e) => handleForm(e)}
              value={brokerInfo.agency_name}
              type="text"
              placeholder="*Nombre Agencia de Seguros"
              name="agency_name"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label>
            <input
              onChange={(e) => handleForm(e)}
              value={brokerInfo.location_agency}
              type="text"
              placeholder="*Ciudad Ubicación Agencia"
              name="location_agency"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label>
            <input
              onChange={(e) => handleForm(e)}
              value={brokerInfo.name}
              type="text"
              placeholder="*Nombre y Apellido"
              name="name"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label>
            <input
              onChange={(e) => handleForm(e)}
              value={brokerInfo.email}
              type="email"
              placeholder="*Correo Electrónico"
              name="email"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label>
            <input
              onChange={(e) => handleForm(e)}
              value={brokerInfo.phone}
              type="text"
              placeholder="*Número de Contacto"
              name="phone"
              maxLength={10}
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label>
            <select
              onChange={(e) => handleForm(e)}
              name="type"
              value={brokerInfo.type}
            >
              <option value="">--Seleccione una opción--</option>
              <option value="AP">AP</option>
              <option value="Autos">Autos</option>
              <option value="Bicicletas">Bicicletas</option>
              <option value="Exequias">Exequias</option>
              <option value="Hogar">Hogar</option>
              <option value="Mascotas">Mascotas</option>
              <option value="Motos">Motos</option>
              <option value="Salud">Salud</option>
              <option value="Soat">Soat</option>
              <option value="Vida">Vida</option>
            </select>
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
        </div>
        <label>
          <textarea
            onChange={(e) => handleForm(e)}
            value={brokerInfo.message}
            className="input-comment"
            name="message"
            cols="30"
            rows="10"
            placeholder="*Mensaje"
          ></textarea>
          <p className="mandatory-text hide">Campo obligatorio</p>
        </label>
        <label className="advise-label">
          <input
            onChange={(e) => handleForm(e)}
            className="checkbox-input"
            type="checkbox"
            name="checked"
            checked={checkedValue}
          />
          Al continuar aceptas nuestros Términos y Condiciones / Política de
          Privacidad para el tratamiento de tus datos
        </label>
        <button disabled={disabledBtn} className="submit-btn">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default FormWWU;
