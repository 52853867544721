import React from "react";

//Components
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import BodyNotFoundPage from "../../components/BodyNotFoundPage/BodyNotFoundPage";

const NotfoundScreen = ({navbarData}) => {
  return (
    <>
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
      <BodyNotFoundPage />
    </>
  );
};

export default NotfoundScreen;
