import React from "react";

//Components
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import JobOfferPageBody from "../../components/JobOfferPageBody/JobOfferPageBody";

const JobOfferPageScreen = ({ navbarData }) => {
  return (
    <>
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
      <JobOfferPageBody />
    </>
  );
};

export default JobOfferPageScreen;
